/* eslint-disable max-len */
export const DB_API_KEY = 'AIzaSyAgdJ0PKEnmLCvwYWJtyTaFvMnheFFzRAQ';
export const GOOGLE_MAPS_KEY = DB_API_KEY;
export const STAGING_API_KEY =
  'pk_live_51NpMJmEGIuJxcjmDIoMxgP6LCOHCczBS7P7BIXNNDjLH0kehYn4hPFG01UD3UrwRlYDV4tD4V8nPZXIRKzPIbgep00BFx1EEdR';
export const STAGING_STRIPE_API_KEY =
  'pk_live_51NpMJmEGIuJxcjmDIoMxgP6LCOHCczBS7P7BIXNNDjLH0kehYn4hPFG01UD3UrwRlYDV4tD4V8nPZXIRKzPIbgep00BFx1EEdR';
export const STAGING_GOOGLE_MAPS_KEY = 'AIzaSyAgdJ0PKEnmLCvwYWJtyTaFvMnheFFzRAQ';
export const PROD_API_KEY = 'AIzaSyBYqcmfEsrJUmOaZBQYkjGjuNeNLx3gUhw';
export const PROD_GOOGLE_MAPS_KEY = 'AIzaSyAgdJ0PKEnmLCvwYWJtyTaFvMnheFFzRAQ';
export const STRIPE_API_KEY =
  'pk_test_51NpMJmEGIuJxcjmDhTg0RBMOIeuycFTAx35w2swCLzbA0PsYmabLStPEzmvxaVf0D29SHPMykrVgU5ppwpkCudvG00RRZaXfHx';
export const WEATHER_API_KEY = '68527e8a12d44d5b8e5164947240805';
export const DEV_API_KEY = DB_API_KEY;
export const PROD_STRIPE_API_KEY = '';
export const POSTHOG_API_TOKEN = 'phc_zD2Nb43141G5cvgXpZbtjlKAxANlUJ5fZoospjbAWab';
export const ALGOLIA_API_KEY = 'a10d053f8d9bc0d71a202ea5d36ed605';
export const STRIPE_PUBLIC_KEY_DEV =
  'pk_test_51NpMJmEGIuJxcjmDhTg0RBMOIeuycFTAx35w2swCLzbA0PsYmabLStPEzmvxaVf0D29SHPMykrVgU5ppwpkCudvG00RRZaXfHx';
export const STRIPE_PUBLIC_KEY_PROD =
  'pk_live_51NpMJmEGIuJxcjmDIoMxgP6LCOHCczBS7P7BIXNNDjLH0kehYn4hPFG01UD3UrwRlYDV4tD4V8nPZXIRKzPIbgep00BFx1EEdR';
